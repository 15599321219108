import React, { useState, useEffect } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';

import { ApiServiceServerless } from '../../xhr_libs';
import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import { LICENSE_LOOKUP } from '../../helpers/licenses';

const EMPTY_LICENSE = {
  id: 0,
  name: '',
  uuid: '',
  licenses: {},
};

const formCol = {
  fontSize: 14,
};

export default function AdminLicenseModal(props) {
  const { show, onHide, currentLicense, licenseTableSetter } = props;

  const [isSaving, setIsSaving] = useState(false);

  const [newLicense, setNewLicense] = useState({
    ...EMPTY_LICENSE,
  });
  const [hasChange, setHasChange] = useState(false);

  useEffect(() => {
    setNewLicense(cloneDeep(currentLicense));
  }, [currentLicense, show]);

  const handleChangeCheck = (type, status, other_status) => {
    const updatedLicense = cloneDeep(newLicense);
    updatedLicense['licenses'][type][status] =
      !updatedLicense['licenses'][type][status];
    if (updatedLicense['licenses'][type][status] === true) {
      updatedLicense['licenses'][type][other_status] = false;
    }
    setNewLicense(updatedLicense);
    setHasChange(true);
  };

  const handleCancel = () => {
    setNewLicense({
      ...currentLicense,
    });
    setHasChange(false);
  };

  useEffect(() => {
    if (!show) {
      setNewLicense({
        ...EMPTY_LICENSE,
      });
      setHasChange(false);
    }
  }, [show]);

  const handleEditLicense = (orgId, type, active, inactive) => {
    ApiServiceServerless.post(`/licenses/${orgId}`, {
      active: active,
      inactive: inactive,
      license_type: type,
    })

      .then((res) => {
        setIsSaving(false);
        licenseTableSetter(orgId, res.data.id, type, active, inactive);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const handleAddLicense = (orgId, type, active, inactive) => {
    ApiServiceServerless.put(`/licenses/${orgId}`, {
      active: active,
      inactive: inactive,
      license_type: type,
    })
      .then((res) => {
        setIsSaving(false);
        licenseTableSetter(orgId, res.data.id, type, active, inactive);
      })
      .catch((err) => {
        setIsSaving(false);
        throw err;
      });
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    Object.keys(newLicense['licenses']).forEach((type) => {
      if (
        newLicense['licenses'][type]['active'] !==
          currentLicense['licenses'][type]['active'] ||
        newLicense['licenses'][type]['inactive'] !==
          currentLicense['licenses'][type]['inactive']
      ) {
        if (!currentLicense['licenses'][type]['id']) {
          handleAddLicense(
            newLicense['id'],
            type,
            newLicense['licenses'][type]['active'],
            newLicense['licenses'][type]['inactive']
          );
        } else {
          handleEditLicense(
            newLicense['id'],
            type,
            newLicense['licenses'][type]['active'],
            newLicense['licenses'][type]['inactive']
          );
        }
      }
    });
    onHide();
    setIsSaving(false);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Edit Licenses</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'scroll' }}>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  type='text'
                  value={newLicense['name']}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Organization UUID</Form.Label>
                <Form.Control
                  type='text'
                  value={newLicense['uuid']}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>

          {['analysis', 'urjanet', 'plug', 'ghg', 'alerts', 'solar'].map(
            (type) => (
              <div key={type}>
                {type in newLicense['licenses'] && (
                  <Row key={type}>
                    <Col>
                      <>{LICENSE_LOOKUP[type] + ' License'}</>
                      <Row>
                        <Form.Group style={formCol} as={Col}>
                          <Form.Label>Active</Form.Label>
                          <Form.Check inline className='ml-3 align-text-bottom'>
                            <Form.Check.Input
                              checked={newLicense['licenses'][type]['active']}
                              onChange={() =>
                                handleChangeCheck(type, 'active', 'inactive')
                              }
                            />
                          </Form.Check>
                        </Form.Group>
                        <Form.Group style={formCol} as={Col}>
                          <Form.Label>Inactive</Form.Label>
                          <Form.Check inline className='ml-3 align-text-bottom'>
                            <Form.Check.Input
                              checked={newLicense['licenses'][type]['inactive']}
                              onChange={() =>
                                handleChangeCheck(type, 'inactive', 'active')
                              }
                            />
                          </Form.Check>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <EditEntityActionButtons
            onSubmit={handleSubmit}
            onDelete={handleCancel}
            onCancel={onHide}
            deleteDisabled={!hasChange}
            submitDisabled={!hasChange}
            deleteText={'Clear Changes'}
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
