import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { orderBy } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';
import OrganizationTypes from '../../../helpers/OrganizationTypes';
import AddressWrapper from '../../../helpers/AddressWrapper';
import { ROLES, ROLE_NAMES_UPPER } from '../../../helpers/RolesData';
import AddEntityActionButtons from '../../../components/forms/AddEntityActionButtons';
import { forceAuthRefreshAsync } from '../../../xhr_libs';

const formStyle = {
  textAlign: 'left',
};

const orgDataDefaults = {
  name: '',
  report_name: '',
  address: {},
  organization_type: null,
  portfolio_role: '',
  portfolio_id: -1,
};

function AddOrganizationModal(props) {
  const { show, onHide, portfolios, setPortfolios, showToast } = props;

  const [organizationData, setOrganizationData] = useState(orgDataDefaults);
  const [validated, setValidated] = useState(false);
  const [validAddress, setValidAddress] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const saveOrganization = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let orgData = {
      name: organizationData.name,
      report_name: organizationData.report_name,
      address: organizationData.address,
      organization_type: organizationData.organization_type,
    };
    let portfolioData = {};
    if (
      organizationData.portfolio_id &&
      organizationData.portfolio_id !== -1 &&
      'portfolio_role' in organizationData &&
      organizationData.portfolio_role !== ''
    ) {
      portfolioData['portfolio_id'] = organizationData.portfolio_id;
      portfolioData['portfolio_role'] = organizationData.portfolio_role;
    }
    if (validAddress && form.checkValidity()) {
      setIsLoading(true);
      ApiServiceServerless.post(`/organizations`, {
        organization_data: orgData,
        portfolio_data: portfolioData,
      })
        .then((res) => {
          setPortfolios((prev) =>
            prev.map((portfolio) => {
              if (portfolio.id === -1)
                return {
                  ...portfolio,
                  organizations: [...portfolio.organizations, { ...res.data }],
                };
              if (
                organizationData.portfolio_id !== -1 &&
                organizationData.portfolio_id === portfolio.id
              )
                return {
                  ...portfolio,
                  organizations: [...portfolio.organizations, { ...res.data }],
                };
              return portfolio;
            })
          );
        })
        .then(async () => {
          await forceAuthRefreshAsync();

          showToast(
            'success_button',
            'Success',
            'New organization ' + organizationData.name + ' was created.'
          );
        })
        .catch(() => {
          showToast('danger', 'Error', 'Organization Creation Error');
        })
        .finally(() => {
          setOrganizationData(orgDataDefaults);
          setValidated(false);
          setValidAddress(false);
          onHide();
          setIsLoading(false);
        });
    } else {
      setValidated(true);
    }
  };

  const handleCancel = () => {
    onHide();
    setValidated(false);
    setValidAddress(true);
    setOrganizationData(orgDataDefaults);
  };

  const saveDisabled =
    !organizationData.name ||
    !validAddress ||
    (organizationData.portfolio_id !== -1 &&
      organizationData.portfolio_role === '');

  return (
    <Modal show={show} onHide={onHide}>
      <Form
        noValidate
        validated={validated}
        onSubmit={saveOrganization}
        style={formStyle}
      >
        <Modal.Header closeButton>
          <div as='h5'>Create an Organization</div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='newOrgForm.orgName'>
                <Form.Label>
                  Name
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  required
                  placeholder=''
                  onChange={(e) =>
                    setOrganizationData({
                      ...organizationData,
                      name: e.target.value,
                    })
                  }
                  maxLength={80}
                />
              </Form.Group>

              <Form.Group controlId='newOrgForm.orgReportName'>
                <Form.Label>Name for Reports</Form.Label>
                <Form.Control
                  placeholder=''
                  onChange={(e) =>
                    setOrganizationData({
                      ...organizationData,
                      report_name: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <AddressWrapper
                address={organizationData.address}
                onAddressSelect={(value) =>
                  setOrganizationData({
                    ...organizationData,
                    address: value,
                  })
                }
                setValidAddress={setValidAddress}
              />
              <Form.Group controlId='newOrg.type'>
                <Form.Label>Organization type </Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) =>
                    setOrganizationData({
                      ...organizationData,
                      organization_type: e.target.value,
                    })
                  }
                  value={organizationData.organization_type || ''}
                >
                  <option key={'select-type'} value={''}></option>
                  {Object.keys(OrganizationTypes).map((data, index) => (
                    <option key={index} value={data}>
                      {OrganizationTypes[data]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Portfolio </Form.Label>
                <Form.Control
                  data-id='port-role'
                  as='select'
                  value={organizationData.portfolio_id}
                  onChange={(e) => {
                    setOrganizationData({
                      ...organizationData,
                      portfolio_id: parseInt(e.target.value),
                      portfolio_role: '',
                    });
                  }}
                >
                  {orderBy(
                    portfolios,
                    ['is_summary', 'name'],
                    ['desc', 'asc']
                  ).map((portfolio) => (
                    <option key={portfolio.id} value={portfolio.id}>
                      {portfolio.id === -1 ? 'None' : portfolio.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {organizationData.portfolio_id &&
                organizationData.portfolio_id !== -1 && (
                  <Form.Group>
                    <Form.Label>Portfolio Role</Form.Label>
                    <Form.Control
                      data-id='port-role'
                      as='select'
                      value={organizationData.role}
                      onChange={(e) => {
                        setOrganizationData({
                          ...organizationData,
                          portfolio_role: e.target.value,
                        });
                      }}
                    >
                      <option key={''} value={''}>
                        None
                      </option>
                      {ROLES.map((role) => (
                        <option key={role} value={role}>
                          {ROLE_NAMES_UPPER[role]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            submitDisabled={saveDisabled || isLoading}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AddOrganizationModal.propTypes = {
  portfolioId: PropTypes.number,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  handleSetOrganization: PropTypes.func,
  showToast: PropTypes.func,
};

export default AddOrganizationModal;
