import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import PopoverTrigger from '../../components/PopoverTrigger';

const defaultColor = '#212529';

function LicenseCell(props) {
  const { license } = props;

  const [fontColor1, setFontColor1] = useState(defaultColor);
  const [fontColor2, setFontColor2] = useState(defaultColor);

  useEffect(() => {
    if (license.stripe === false) {
      setFontColor1('red');
    } else if (license.stripe === true) {
      setFontColor1('green');
    } else {
      setFontColor1(defaultColor);
    }
    if (license.active) {
      setFontColor2('green');
    } else if (license.inactive) {
      setFontColor2('red');
    } else {
      setFontColor2(defaultColor);
    }
  }, [license]);

  return (
    <Col>
      <div
        style={{
          color: fontColor1,
          padding: '0',
          width: '125px',
          textAlign: 'center',
        }}
      >
        {license.stripe !== null ? (
          <>
            <div>
              Stripe:
              <PopoverTrigger
                rootClose={true}
                popoverTitle={'License Dates'}
                popoverContent={
                  <div>
                    <div>License Start: {license.license_start}</div>
                    <div>License End: {license.license_end}</div>
                  </div>
                }
                trigger={'click'}
              >
                <div
                  style={{
                    display: 'inline-block',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {license.stripe ? 'Active' : 'Inactive'}
                </div>
              </PopoverTrigger>
            </div>
          </>
        ) : (
          'Stripe: -'
        )}
      </div>
      <div
        style={{
          color: fontColor2,
          padding: '0',
          width: '125px',
          textAlign: 'center',
        }}
      >
        {license.active && 'Force: Active'}
        {license.inactive && 'Force: Inactive'}
        {!license.active && !license.inactive && 'Force: -'}
      </div>
    </Col>
  );
}

export default LicenseCell;
