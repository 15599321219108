import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingMemo, faBuildings } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import Loader from '../../components/Loader';
import EnergyTracerTable from '../../components/tables';
import FilterInput from '../../components/FilterInput';
import AdminOrganizationUserModal from '../../components/modals/AdminOrganizationUserModal';
import AdminPortfolioUserModal from '../../components/modals/AdminPortfolioUserModal';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function AdminUsers(props) {
  const { showToast } = props;

  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);

  const [tableUsers, setTablesUsers] = useState([]);

  const [emailFilter, setEmailFilter] = useState('');
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');

  const [activeUser, setActiveUser] = useState({ id: null, email: '' });
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiServiceServerless.get(`/admin/users`)
      .then(({ data }) => {
        setUsers(data['users']);
        setOrganizations(data['organizations']);
        setPortfolios(data['portfolios']);
        setIsLoading(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setIsLoading(false);
        throw err;
      });
  }, [showToast]);

  useEffect(() => {
    let table_users = [...users];

    if (emailFilter !== '') {
      table_users = table_users.filter((user) =>
        user.email.toLowerCase().includes(emailFilter.toLowerCase())
      );
    }

    if (firstNameFilter !== '') {
      table_users = table_users.filter((user) =>
        user.first_name.toLowerCase().includes(firstNameFilter.toLowerCase())
      );
    }

    if (lastNameFilter !== '') {
      table_users = table_users.filter((user) =>
        user.last_name.toLowerCase().includes(lastNameFilter.toLowerCase())
      );
    }

    setTablesUsers(table_users);
  }, [users, emailFilter, firstNameFilter, lastNameFilter]);

  const COLUMNS = [
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'first_name',
      text: 'First Name',
    },
    {
      dataField: 'last_name',
      text: 'Last Name',
    },
    {
      dataField: 'id',
      formatter: (cell, row) => (
        <div
          style={{
            marginTop: '1em',
            marginBottom: '1em',
            textAlign: 'right',
          }}
        >
          <FontAwesomeIcon
            style={styles.iconStyle}
            icon={faBuildingMemo}
            title={'Add Portfolio'}
            onClick={() => {
              setActiveUser(row);
              setShowPortfolioModal(true);
            }}
          />
          <FontAwesomeIcon
            style={styles.iconStyle}
            icon={faBuildings}
            title={'Add Organization'}
            onClick={() => {
              setActiveUser(row);
              setShowOrganizationModal(true);
            }}
          />
        </div>
      ),

      text: 'Actions',
    },
  ];

  return (
    <>
      {' '}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <FilterInput
              label='Filter Email'
              filterValue={emailFilter}
              setFilterValue={setEmailFilter}
              size={3}
            />
            <FilterInput
              label='Filter First Name'
              filterValue={firstNameFilter}
              setFilterValue={setFirstNameFilter}
              size={3}
            />
            <FilterInput
              label='Filter Last Name'
              filterValue={lastNameFilter}
              setFilterValue={setLastNameFilter}
              size={3}
            />
          </Row>
          <EnergyTracerTable
            data={tableUsers}
            columns={COLUMNS}
            keyField='email'
          />
          <AdminOrganizationUserModal
            onHide={() => {
              setActiveUser(
                { id: null, name: '' },
                setShowOrganizationModal(false)
              );
            }}
            show={showOrganizationModal}
            activeUser={activeUser}
            organizations={organizations}
            showToast={showToast}
          />
          <AdminPortfolioUserModal
            onHide={() => {
              setActiveUser(
                { id: null, name: '' },
                setShowPortfolioModal(false)
              );
            }}
            show={showPortfolioModal}
            activeUser={activeUser}
            portfolios={portfolios}
            showToast={showToast}
          />
        </>
      )}
    </>
  );
}
