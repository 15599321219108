import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { sortBy } from 'lodash';

import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import { ROLES, ROLE_NAMES_UPPER } from '../../helpers/RolesData';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AdminOrganizationUserModal(props) {
  const { onHide, show, activeUser, organizations, showToast } = props;

  const [isSaving, setIsSaving] = useState(false);

  const [organizationId, setOrganizationId] = useState('');
  const [role, setRole] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSaving(true);
    ApiServiceServerless.post(`/admin/organization_user/${organizationId}`, {
      email: activeUser.email,
      role: role,
    })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      })
      .finally(() => {
        onHide();
        clearFields();
        setIsSaving(false);
      });
  };

  const clearFields = () => {
    setOrganizationId('');
    setRole('');
  };

  const handleCancel = () => {
    clearFields();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Invite New Member</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Row>
                <Form.Group as={Col} controlId='formEmail'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control value={activeUser.email} disabled={true} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId='formOrg'>
                  <Form.Label>Organization</Form.Label>
                  <Form.Control
                    as='select'
                    value={organizationId}
                    onChange={(e) => setOrganizationId(e.target.value)}
                  >
                    <option key={''} value={''} disabled hidden>
                      {''}
                    </option>
                    {sortBy(organizations, 'name').map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId='formRole'>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as='select'
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option key={''} value={''} disabled hidden>
                      {''}
                    </option>
                    {ROLES.map((role) => (
                      <option key={role} value={role}>
                        {ROLE_NAMES_UPPER[role]}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            isSubmitting={isSaving}
            submitDisabled={role === '' || organizationId === ''}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
