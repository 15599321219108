import React from 'react';
import { Form, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

const styles = {
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.5s linear',
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s 0.5s, opacity 0.5s linear',
  },
  cancelStyle: {
    position: 'absolute',
    right: '25px',
    top: '33px',
    fontSize: '18px',
    lineHeight: 'inherit',
    color: 'red',
    cursor: 'pointer',
  },
};

export default function FilterInput(props) {
  const { label, filterValue, setFilterValue, size } = props;

  return (
    <Form.Group as={Col} md={size}>
      <Form.Label style={{ fontSize: '0.9rem', marginBottom: '0' }}>
        <div style={filterValue !== '' ? styles.visible : styles.hidden}>
          {label}
        </div>
      </Form.Label>
      <div>
        <Form.Control
          placeholder={label}
          value={filterValue ? filterValue : ''}
          onChange={(e) => setFilterValue(e.target.value)}
          style={{
            display: 'inline',
          }}
        />
        {filterValue && (
          <FontAwesomeIcon
            style={styles.cancelStyle}
            icon={faTimesCircle}
            onClick={() => setFilterValue('')}
          />
        )}
      </div>
    </Form.Group>
  );
}
