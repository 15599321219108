import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Helmet from 'react-helmet';

import Organization from './Organization';
import Loader from '../../components/Loader';
import Building from './Building';
import UtilityAccount from './UtilityAccount';
import { DEFAULT_BUILDING_VALUES, DEFAULT_ACCOUNT_VALUES } from './helpers';
import ResourceNav from './ResourceNav';
import NavigationAlert from '../../components/analysis/NavigationAlert';

const PAGE_TITLE = 'Resources';

export default function Resources(props) {
  const {
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    showToast,
    setDisableOrganizationSelect,
  } = props;

  const [selectedResource, setSelectedResource] = useState('organization');

  const [organization, setOrganization] = useState({ id: null });
  const [buildingInfo, setBuildingInfo] = useState({ id: null });
  const [building, setBuilding] = useState(DEFAULT_BUILDING_VALUES);
  const [utilityAccountInfo, setUtilityAccountInfo] = useState({ id: null });
  const [utilityAccount, setUtilityAccount] = useState(DEFAULT_ACCOUNT_VALUES);

  const [OBAUpdate, setOBAUpdate] = useState(0);
  const [OPAUpdate, setOPAUpdate] = useState(0);
  const [OBADateUpdates, setOBADateUpdates] = useState([]);

  const [buildingFilterValue, setBuildingFilterValue] = useState('');
  const [accountFilterValue, setAccountFilterValue] = useState('');
  const [utilityFilterValue, setUtilityFilterValue] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const resetUtilityAccount = useCallback(() => {
    setUtilityAccountInfo({ id: null });
    setUtilityAccount(DEFAULT_ACCOUNT_VALUES);
  }, []);

  const resetBuilding = useCallback(() => {
    setBuildingInfo({ id: null });
    setBuilding(DEFAULT_BUILDING_VALUES);
    resetUtilityAccount();
  }, [resetUtilityAccount]);

  const resetOrganization = useCallback(() => {
    setSelectedResource('organization');
    setOrganization({ id: null });
    resetBuilding();
  }, [resetBuilding]);

  useEffect(() => {
    resetOrganization();
    if (!userSelectedOrganization.id) {
      setIsLoading(false);
    }
  }, [userSelectedOrganization.id, resetOrganization]);

  useEffect(() => {
    setDisableOrganizationSelect(isLoading);
  }, [isLoading, setDisableOrganizationSelect]);

  useEffect(() => {
    setBuildingInfo({ id: building.id, name: building.name });
  }, [building]);

  useEffect(() => {
    setUtilityAccountInfo({
      id: utilityAccount.id,
      name: utilityAccount.account_number,
    });
  }, [utilityAccount]);

  const handleUpdateTables = (setter) => {
    if (setter === 0 || setter === 1) {
      setOBAUpdate((prev) => prev + 1);
    }
    if (setter === 0 || setter === 2) {
      setOPAUpdate((prev) => prev + 1);
    }
  };

  const handleNewMaxDate = (update) => {
    setOBADateUpdates((prev) => [...prev, update]);
  };

  const checkResourceReclick = useCallback(() => {
    setSelectedResource('organization');
    resetBuilding();
  }, [resetBuilding]);

  window.addEventListener('energytracer_resource_click', checkResourceReclick);

  return (
    <Container className='et-container'>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>{' '}
      <>
        {userSelectedOrganization.id ? (
          <>
            <div style={{ position: 'relative', zIndex: '100' }}>
              <ResourceNav
                selectedResource={selectedResource}
                setSelectedResource={setSelectedResource}
                organizationInfo={userSelectedOrganization}
                buildingInfo={buildingInfo}
                utilityAccountInfo={utilityAccountInfo}
                resetBuilding={resetBuilding}
                resetUtilityAccount={resetUtilityAccount}
              />
            </div>
            <Row style={{ padding: '1em' }}>
              <Col>
                {isLoading && <Loader />}
                <Organization
                  organization_id={userSelectedOrganization.id}
                  userSelectedOrganization={userSelectedOrganization}
                  userSelectedOrganizationDetails={
                    userSelectedOrganizationDetails
                  }
                  selectedResource={selectedResource}
                  setSelectedResource={setSelectedResource}
                  organization={organization}
                  setOrganization={setOrganization}
                  buildingInfo={buildingInfo}
                  setBuildingInfo={setBuildingInfo}
                  utilityAccountInfo={utilityAccountInfo}
                  setUtilityAccountInfo={setUtilityAccountInfo}
                  OBAUpdate={OBAUpdate}
                  OPAUpdate={OPAUpdate}
                  handleUpdateTables={handleUpdateTables}
                  OBADateUpdates={OBADateUpdates}
                  setOBADateUpdates={setOBADateUpdates}
                  buildingFilterValue={buildingFilterValue}
                  setBuildingFilterValue={setBuildingFilterValue}
                  accountFilterValue={accountFilterValue}
                  setAccountFilterValue={setAccountFilterValue}
                  utilityFilterValue={utilityFilterValue}
                  setUtilityFilterValue={setUtilityFilterValue}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isSaving={isSaving}
                  setIsSaving={setIsSaving}
                  isDeleting={isDeleting}
                  setIsDeleting={setIsDeleting}
                  showToast={showToast}
                />
                {buildingInfo.id && (
                  <Building
                    userSelectedOrganization={userSelectedOrganization}
                    userSelectedOrganizationDetails={
                      userSelectedOrganizationDetails
                    }
                    selectedResource={selectedResource}
                    setSelectedResource={setSelectedResource}
                    buildingInfo={buildingInfo}
                    setBuildingInfo={setBuildingInfo}
                    building={building}
                    setBuilding={setBuilding}
                    utilityAccountInfo={utilityAccountInfo}
                    setUtilityAccountInfo={setUtilityAccountInfo}
                    resetBuilding={resetBuilding}
                    OBAUpdate={OBAUpdate}
                    handleUpdateTables={handleUpdateTables}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    isDeleting={isDeleting}
                    setIsDeleting={setIsDeleting}
                    showToast={showToast}
                  />
                )}
                {utilityAccountInfo.id && (
                  <UtilityAccount
                    userSelectedOrganization={userSelectedOrganization}
                    userSelectedOrganizationDetails={
                      userSelectedOrganizationDetails
                    }
                    selectedResource={selectedResource}
                    setSelectedResource={setSelectedResource}
                    setBuildingInfo={setBuildingInfo}
                    utilityAccountInfo={utilityAccountInfo}
                    setUtilityAccountInfo={setUtilityAccountInfo}
                    utilityAccount={utilityAccount}
                    setUtilityAccount={setUtilityAccount}
                    resetBuilding={resetBuilding}
                    handleNewMaxDate={handleNewMaxDate}
                    handleUpdateTables={handleUpdateTables}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    isDeleting={isDeleting}
                    setIsDeleting={setIsDeleting}
                    showToast={showToast}
                  />
                )}
              </Col>
            </Row>
          </>
        ) : (
          <NavigationAlert
            mainText={'It looks like you do not have any organizations yet.'}
            buttonText={'New Organization'}
            location={'/portfolios'}
          />
        )}
      </>
    </Container>
  );
}
